import React from "react";

import Loader from "./Loader";
import { isEmpty } from "./Util";

const TabelaPadrao = ({
  title,
  height,
  noOverflowAuto, //remove a scroll bar da tela
  fontSize = "12px",
  colunas,
  itens,
  itensFiltrados,
  filtro = "",
  carregando,
  children,
  rowSpan,
  qtdColunas,
  noHover, // Remove o hover das linhas da tabela
  cabecalhoFixo = false, //Fixa o thead/cabeçalho no topo da tela
}) => {
  let colSpan = 0;

  return (
    <form className="bg-white" onSubmit={(e) => e.preventDefault()}>
      {title && (
        <div className="text-center text-muted small p-2 border border-bottom-0">
          {title}
        </div>
      )}
      <div
        className={noOverflowAuto ? "" : "overflow-auto"}
        style={{ height: height ? height : noOverflowAuto ? "-" : "53vh" }}
      >
        <div className="table-responsive-sm">
          <table
            className={`table table-sm ${
              noHover ? "" : "table-hover"
            } table-bordered`}
            style={{ fontSize: fontSize }}
          >
            <thead style={{ backgroundColor: "#BFBFBF", ...(cabecalhoFixo && {position: "sticky", top: 0, zIndex: 10})}}>
              {rowSpan ? (
                colunas
              ) : (
                <tr>
                  {colunas.map(
                    (coluna, index) =>
                      coluna !== false && (
                        <th
                          key={index}
                          className={`text-${
                            coluna.text ? coluna.text : "left"
                          }`}
                          colSpan={coluna.colSpan && coluna.colSpan}
                          style={{ borderBottom: "none" }}
                          width={coluna.width}
                        >
                          {coluna.nome}
                          <div className="d-none">
                            {coluna.colSpan && colSpan++}
                          </div>
                        </th>
                      )
                  )}
                </tr>
              )}
            </thead>
            <tbody style={{ borderTop: 0 }}>
              {isEmpty(itens) ||
              (isEmpty(itensFiltrados) && filtro !== "") ||
              carregando ? (
                <tr className="text-center">
                  <td colSpan={rowSpan ? qtdColunas : colunas.length + colSpan}>
                    {carregando ? (
                      <Loader />
                    ) : isEmpty(itensFiltrados) && filtro !== "" ? (
                      `Não existe nenhum resultado para ${filtro}.`
                    ) : (
                      "Não existem itens para serem exibidos."
                    )}
                  </td>
                </tr>
              ) : (
                children
              )}
            </tbody>
          </table>
        </div>
      </div>
    </form>
  );
};

export default TabelaPadrao;
